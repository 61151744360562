import { Spin } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSessionApi } from '../api/session';
import { UserContainer } from '../pages/dashboard/styles';
import { setCustomerLoading } from '../redux/actions/customerAction';
import { setSessionData } from '../redux/actions/sessionAction';
import { Role } from '../utils/types/role';
import AddSessionNoteComponent from './AddUser/AddSessionNoteComponent';
import { CustomerContainer } from './common/styles';
import MySessionTableComponent from './TableData/MySessionTableComponent';

const MySessionComponent: React.FC<any> = (props) => {
  const { dispatch, customerLoading, customerSelectedMember, sessionData, currentLoginUser } =
    props;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('sessionId');

  const [chooseKey, setKey] = useState<string>('Active');

  useEffect(() => {
    dispatch(setCustomerLoading(true));
    if (customerSelectedMember && customerSelectedMember[0]) {
      getSessionApi(customerSelectedMember[0]._id).then((res) => {
        dispatch(setSessionData(res.data));
        dispatch(setCustomerLoading(false));
      });
    }
  }, [dispatch, customerSelectedMember]);

  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
  }, [chooseKey]);

  const todaySessionContainerRef = useRef<HTMLDivElement>(null);
  return (
    <div>
      <Spin spinning={customerLoading}>
        <UserContainer className="flex flex-wrap">
          <div ref={todaySessionContainerRef} />
          {currentLoginUser.type === 'BusinessUser' ? (
            <AddSessionNoteComponent
              onCloseDrawer={onCloseDrawer}
              user={Role.Customer}
              formTypeLabel="Activity"
              addButtonLabel="Add an Activity Note"
            />
          ) : null}
        </UserContainer>
        {sessionId && (
          <AddSessionNoteComponent
            onCloseDrawer={onCloseDrawer}
            user={Role.Customer}
            formTypeLabel="Activity"
            chooseForm="Edit"
            sessionId={sessionId}
            directlyCallComponent
            notifyVisible
            icon={null}
          />
        )}
        <div className="site-layout-background">
          <div className="site-card-wrapper">
            <CustomerContainer>
              <MySessionTableComponent
                todaySessionContainerEl={todaySessionContainerRef.current}
                sessionData={sessionData}
                loginUserType={currentLoginUser.type}
              />
            </CustomerContainer>
          </div>
        </div>
      </Spin>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    programSkills: state.programRuducer.programSkills,
    programComlexityList: state.programRuducer.programComlexityList,
    customerLoading: state.customerRuducer.customerLoading,
    sessionData: state.sessionReducer.sessionData,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(MySessionComponent);
