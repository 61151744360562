import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import './tailwind.css';

import { Provider } from 'react-redux';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import './style/index.scss';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getToken, initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { setDataInLocalStorage } from './components/common/utils';

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

(async function () {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = JSON.parse(process.env.BE_FIREBASE_CONFIG!);
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
  // site key and pass it to initializeAppCheck().
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.FIREBASE_APPCHECK_DEBUG_TOKEN;
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(process.env.BE_RECAPTCHA_KEY!),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
  });

  // The appCheck token can be retrieved by calling the getToken method.
  const { token } = await getToken(appCheck);
  setDataInLocalStorage('appCheckToken', token);

  ReactDOM.render(
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
