import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { setVisiblePopup } from '../redux/actions/customerAction';
import { Role } from '../utils/types/role';
import AddSessionNoteComponent from './AddUser/AddSessionNoteComponent';

const ViewEditPopOver: React.FC<any> = (props) => {
  const {
    onCloseDrawer,
    sessionId,
    visiblePopup,
    sessionRead,
    dispatch,
    loginUserType,
    viewDrawer,
    SessionReadFun,
    onRow
  } = props;
  const [visible, setVisible] = useState(false);
  const [showSession, setSession] = useState(false);
  const [showEditSession, setEditSession] = useState(false);
  const [imageIcon, setImageIcon] = useState(false);

  useEffect(() => {
    if (viewDrawer) {
      viewMode();
    }
  }, []);

  const closeDrawer = () => {
    if (showSession) {
      setSession(false);
    }
    if (showEditSession) {
      setEditSession(false);
    }
    setImageIcon(false);
    if (props.onCloseDrawer) {
      props.onCloseDrawer();
    }
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible);
    setImageIcon(visible);
  };

  const viewMode = () => {
    setSession(true);
    setVisible(false);
    dispatch(setVisiblePopup(true));
    if (!sessionRead) {
      props.SessionReadFun(sessionId);
    }
  };
  const editMode = () => {
    setEditSession(true);
    setVisible(false);
    dispatch(setVisiblePopup(true));
  };
  const SessionViewTitle = <span className="title-styling">View</span>;
  const SessionEditTitle = <span className="title-styling">Edit</span>;

  return (
    <>
      {/* <Popover
        content={
          <div className="popoverViewStyle">
            {loginUserType === Role.Business && (
              <a onClick={editMode}>{SessionEditTitle}</a>
            )}

            <a onClick={viewMode}>{SessionViewTitle} </a>
          </div>
        }
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
        overlayClassName="PopOverContainer"

      >
        <div>
          {" "}
          {imageIcon ? (
            <img src={ThreeBlueDotsIcon} />
          ) : (
            <img src={ThreeDotsIcon} />
          )}
        </div>
      </Popover> */}
      {viewDrawer ? (
        <AddSessionNoteComponent
          onCloseDrawer={closeDrawer}
          user={Role.Customer}
          formTypeLabel="Activity"
          chooseForm="View"
          icon={<EditOutlined key="eye" />}
          edit={false}
          directlyCallComponent={visiblePopup}
          sessionId={sessionId}
          notifyVisible
        />
      ) : null}
      {/* {
       loginUserType === Role.Business && (
        <AddSessionNoteComponent
          onCloseDrawer={closeDrawer}
          user={Role.Customer}
          formTypeLabel={"Session"}
          chooseForm="Edit"
          edit={false}
          icon={<EditOutlined key="eye" />}
          directlyCallComponent={visiblePopup}
          sessionId={sessionId}
          notifyVisible={true}
        />
      ) }  */}
    </>
  );
};

export default ViewEditPopOver;
