import { DownloadOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Space, Table } from 'antd';
import React, { useState } from 'react';

import { exportSessionReportApi } from '../../api/sessionReport';
import { EditViewIcon } from '../common/styles';
import { getDataFromLocalStorage } from '../common/utils';
import SessionReportModal from './SessionReportModal';
import { ReportTypes } from './types';

const ReportsTable: React.FC<any> = ({ className, data }) => {
  const [openedModalType, setOpenedModalType] = useState<ReportTypes | null>(null);
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 180,
      render: (text, record) => {
        const { type } = record;
        const payload = getDataFromLocalStorage(type) as SessionReport;
        return (
          <Space size="middle">
            <EditViewIcon className="autismag-table-action">
              <span onClick={() => setOpenedModalType(type)} data-testid="run-report-btn">
                <PlayCircleOutlined key="edit" />
              </span>
              {payload && (
                <span onClick={() => exportReport(type)} data-testid="download-report-btn">
                  <DownloadOutlined key="download" />
                </span>
              )}
            </EditViewIcon>
          </Space>
        );
      }
    }
  ];

  const reports = [
    {
      title: 'Session Report',
      type: ReportTypes.Session
    }
  ];

  const exportReport = async (type: ReportTypes) => {
    const payload = getDataFromLocalStorage(type) as SessionReport;
    if (!payload) return;

    try {
      const data = await exportSessionReportApi(payload);
      // download response
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = () => {
    setOpenedModalType(null);
  };

  return (
    <div>
      <Table className={className} columns={columns} dataSource={reports} scroll={{ x: 1000 }} />

      <SessionReportModal open={openedModalType === ReportTypes.Session} onClose={onClose} />
    </div>
  );
};

export default ReportsTable;
