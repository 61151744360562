import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Checkbox, Form, Input, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getPublicUserApi, getUserApi, patchFirstLogin } from '../../api/user';
import {
  ErrorContainer,
  ForgotPassword,
  InputFieldsContainer,
  LoginButton,
  LoginButtonContainer,
  LoginContainer,
  LogoContainer,
  RememberMe
} from './styles';
// import SiteBlackLogo from "../../images/autism_logo_black.svg";
import { loginApi } from '../../api/login';
import { setDataInLocalStorage } from '../../components/common/utils';
import { LoginFormProps } from '../../constant';
import SiteLogo from '../../images/autism_360_white_new_logo.png';
import SiteBlackLogo from '../../images/icons/logo-text.png';
import { setSelectedMemberData, setWelcomeModal } from '../../redux/actions/customerAction';
import { getCustomerApi } from '../../redux/actions/notificationAction';
import { setCurrentLoginUser } from '../../redux/actions/userMemberAction';
import { Role } from '../../utils/types/role';

const LoginForm: React.FC<RouteComponentProps> = (props) => {
  const { openWelcomeModal, dispatch, isMobile } = props;

  const [loginDetail, setLoginDetail] = useState<LoginFormProps>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const onChange = (e) => {
    const { name, value } = e.target;
    setLoginDetail({ ...loginDetail, [name]: value });
    setErrorMessage('');
  };

  const onLoginClick = () => {
    setErrorMessage('');
    setLoading(true);
    loginApi(loginDetail)
      .then((res) => {
        const { token, gcpToken, ...data } = res.data;

        setDataInLocalStorage('token', token);
        setDataInLocalStorage('gcpToken', gcpToken);
        dispatch(setCurrentLoginUser(data));
        setLoading(false);
        props.history.push('/dashboard');

        if (data?.type === 'Customer' && data.members?.[0]?.primaryContact) {
          getPublicUserApi(data.members?.[0].primaryContact, 'BusinessUser').then((res) => {
            dispatch(setSelectedMemberData([{ ...data.members?.[0], primaryContactObject: res }]));
          });
        }

        if (data.firstTimeLogin === true) {
          patchFirstLogin(data._id).then((res) => {
            getUserApi(data._id).then((res) => {
              const { data } = res;
              dispatch(getCustomerApi(data));
            });
          });
        }
        if (data.type === Role.Customer && data.firstTimeLogin === true) {
          dispatch(setWelcomeModal(true));
        } else if (data.type === Role.Customer && data.firstTimeLogin === false) {
          dispatch(setWelcomeModal(false));
        }
      })
      .catch((err) => {
        if (err.response) {
          setErrorMessage(err.response.data.message);
        } else {
          setErrorMessage('something went wrong');
        }
        setLoading(false);
      });
  };
  const handlescreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handlescreenSize);
  }, []);

  return (
    <div>
      <LoginContainer>
        <LogoContainer>
          <div className="Signin-container">
            <div className="Background-rectangle-image">
              <div className="RectangleImage" />
            </div>

            <div className="sideContainer">
              <div className="sideMenu">
                <div className="Signin-image">
                  <div className="LogoIcons">
                    <img src={isMobile ? SiteLogo : SiteBlackLogo} style={{ width: '150px' }} />
                  </div>
                  <div className="welcomeText">
                    <div className="SignInText"> Sign In to </div>
                    <div className="autismText">
                      {' '}
                      autism
                      <span className="autism360Text">360</span>
                    </div>
                    <div className="credentialsText">
                      {' '}
                      Enter your credentials
                      <br /> below here{' '}
                    </div>
                  </div>
                </div>
                <Spin spinning={isLoading}>
                  <Form id="loginForm" size="large" layout="vertical" onFinish={onLoginClick}>
                    <InputFieldsContainer style={{ marginTop: '17px' }}>
                      <Form.Item
                        label="E-mail"
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            required: true,
                            message: 'Please Enter Email!',
                            whitespace: true
                          }
                        ]}
                      >
                        <Input
                          type="email"
                          placeholder="Enter your Email"
                          name="email"
                          onChange={onChange}
                          disabled={!!isLoading}
                          className="InputFieldsContainer"
                        />
                      </Form.Item>
                    </InputFieldsContainer>
                    <InputFieldsContainer>
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                            whitespace: true
                          },
                          {
                            pattern: new RegExp(/(?=.{8,}$).*/),
                            message: 'Minimum 8 characters!'
                          }
                        ]}
                      >
                        <Input.Password
                          // pattern="(?=.{8,}$)(?=.*\W).*"
                          placeholder="Enter your Password"
                          name="password"
                          onChange={onChange}
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                          disabled={!!isLoading}
                          className="InputFieldsContainer"
                        />
                      </Form.Item>
                    </InputFieldsContainer>
                    <div className="remember-forgot-container">
                      <RememberMe className="remember-me">
                        <Checkbox disabled={!!isLoading} className="is-sign-in">
                          Remember me
                        </Checkbox>
                      </RememberMe>

                      <ForgotPassword className="forget-pass">
                        <Link to="/forget-password">Forgot Password?</Link>
                      </ForgotPassword>
                    </div>
                    {errorMessage !== '' ? <ErrorContainer>{errorMessage}</ErrorContainer> : null}
                    <LoginButtonContainer className="MainLoginButton">
                      <LoginButton
                        className="LoginButton"
                        type="primary"
                        htmlType="submit"
                        disabled={!!isLoading}
                        data-testid="login-button"
                      >
                        Sign In
                      </LoginButton>
                    </LoginButtonContainer>
                    {/* <WelcomeScreen className="welcome-screen">
                    Don’t have an Account?  <Link to={`/forget-password`} className="sign-up">Sign Up </Link>
                      </WelcomeScreen> */}
                  </Form>
                </Spin>
              </div>
            </div>
          </div>
        </LogoContainer>
      </LoginContainer>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    openWelcomeModal: state.customerRuducer.openWelcomeModal,
    isMobile: state.trackReducer.isMobile
  };
}

export default connect(mapStateToProps)(LoginForm);
