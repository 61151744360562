import axios from 'axios';
import qs from 'query-string';
import { getDataFromLocalStorage } from '../components/common/utils';

export function logOut() {
  if (window.refreshTokenInterval) {
    clearInterval(window.refreshTokenInterval);
    window.refreshTokenInterval = null;
  }
  if (window.refreshDataInterval) {
    clearInterval(window.refreshDataInterval);
    window.refreshDataInterval = null;
  }
  localStorage.removeItem('token');
  localStorage.removeItem('gcpToken');
  localStorage.removeItem('bussness access customer');
  localStorage.removeItem('selected form details');
  localStorage.removeItem('selected member');
  localStorage.removeItem('myFormData');
  sessionStorage.removeItem('authentication');
}

export const checkUserAuth = () => getDataFromLocalStorage('token');

export function getHeaders() {
  const token = getDataFromLocalStorage('token');
  const appCheckToken = getDataFromLocalStorage('appCheckToken');

  const headers = {
    Authorization: `Bearer ${token ?? ''}`,
    'Content-Type': 'application/json',
    'X-Firebase-AppCheck': appCheckToken ?? ''
  };
  return headers;
}

export function apiReq(
  endPoint,
  data,
  method,
  headers,
  requestOptions = {},
  shouldRefreshToken = true
) {
  return new Promise((resolve, reject) => {
    headers = {
      ...getHeaders(),
      ...headers
    };
    if (method === 'get' || method === 'delete') {
      data = {
        ...requestOptions,
        params: data,
        headers,
        data: {}
      };
      data.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' });
    }
    axios[method](endPoint, data, { headers, ...requestOptions })
      .then((result) => {
        const { data } = result;
        if (data.status === false) {
          return reject(data);
        }
        return resolve(data);
      })
      .catch((error) => reject(error));
  });
}
export function apiGet(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, 'get', headers, requestOptions);
}
export function apiPost(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, 'post', headers, requestOptions);
}
export function apiPatch(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, 'patch', headers, requestOptions);
}
export function apiPut(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, 'put', headers, requestOptions);
}
export function apiDelete(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, 'delete', headers, requestOptions);
}
