import { Card } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getNotificationApi, putNotificationApi } from '../../api/notification';
import { getGoalsApi, getTaskDetailsApi } from '../../api/user-program';
import { setCustomerLoading } from '../../redux/actions/customerAction';
import {
  getNotificationAlert,
  notificationVisible,
  putNotificationAlert
} from '../../redux/actions/notificationAction';
import { setBannerHeaderLabel } from '../../redux/actions/trackingAction';
import { setMemberGoals } from '../../redux/actions/userProgramAction';
import { Role } from '../../utils/types/role';
import AddGoalComponent from '../AddUser/AddGoalComponent';
import AddMilestone from '../AddUser/AddMilestone';
import AddSessionNoteComponent from '../AddUser/AddSessionNoteComponent';
import AddTasks from '../AddUser/AddTasks';
import NotificationCard from '../common/NotificationComponent/NotificationCard';
import { ProgramRole } from '../MyGoalsComponent';
import WizardModal from '../refactored/Modal/Wizard/Wizard';

const MyNotificationComponent: React.FC<any> = (props) => {
  const { dispatch, notificationList, visibly, currentLoginUser } = props;
  const history = useHistory();
  const filterNotificationTaskList =
    (notificationList &&
      notificationList.length &&
      notificationList
        .filter((item) => (item?.task ? !item.task.isDeleted : true))
        .sort((a, b) => {
          const x = new Date(b.createdAt) as any;
          const y = new Date(a.createdAt) as any;
          return x - y;
        })) ||
    [];
  const [chooseKey, setKey] = useState<string>('Active');
  const [mileStoneNotification, setMileStoneNotification] = useState<any>();
  const [milestoneData, setMilestoneData] = useState<any>();
  const goalInfo = notificationList.filter((data) => data._id === mileStoneNotification);
  const [taskData, setTaskData] = useState<any>();
  const [sessionId, setSessionId] = useState<any>();
  const [taskId, setTaskId] = useState<any>();
  const [itemId, setItemId] = useState<any>();
  const [notificationType, setNotificationType] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [visibility, setVisibility] = useState<boolean>(false);

  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
    dispatch(notificationVisible(false));
  }, [chooseKey]);

  const getGoalsData = () => {
    if (goalInfo && goalInfo.member) {
      getGoalsApi(goalInfo.member).then((res) => {
        dispatch(setMemberGoals(res.data));
        dispatch(setCustomerLoading(false));
      });
    }
  };
  const resourceDataRoute = () => {
    const path = '/dashboard/myresources';
    history.push(path);
    dispatch(setBannerHeaderLabel(path));
    return null;
  };
  const goalDataRoute = () => {
    const path = '/dashboard/myprogram';
    history.push(path);
    dispatch(setBannerHeaderLabel(path));
    return null;
  };
  const renderSwitch = () => {
    switch (notificationType) {
      case 'GOAL_ADDED':
        return (
          <AddGoalComponent
            onCloseDrawer={onCloseDrawer}
            user={Role.Customer}
            addButtonLabel="Add Outcome"
            formTypeLabel="Outcome"
            chooseForm="View"
            propVisible={visibly}
            notifyVisible
            id={goalInfo.length && goalInfo[0] && goalInfo[0].goal._id}
          />
        );

      case 'GOAL_UPDATED':
        return (
          <AddGoalComponent
            onCloseDrawer={onCloseDrawer}
            user={Role.Customer}
            addButtonLabel="Add Outcome"
            formTypeLabel="Outcome"
            chooseForm="View"
            propVisible={visibly}
            notifyVisible
            id={goalInfo.length && goalInfo[0] && goalInfo[0].goal._id}
          />
        );

      case 'MILESTONE_ADDED':
        return (
          <AddMilestone
            getGoalsData={getGoalsData}
            onCloseDrawer={onCloseDrawer}
            user={Role.Customer}
            formTypeLabel="Skill"
            propVisible={visibly}
            chooseForm="View"
            notifyVisible
            goalFormType={ProgramRole.Milestone}
            addButtonLabel="Add Skill"
            goalData={goalInfo}
            mileStoneData={milestoneData}
            milestoneId={itemId && itemId}
          />
        );
      // <AddMilestone getGoalsData={getGoalsData} onCloseDrawer={onCloseDrawer} user={Role.Customer} formTypeLabel="Milestone" propVisible={visibly} chooseForm="View"  goalFormType={ProgramRole.Milestone} addButtonLabel="Add Milestone" goalData={goalInfo} id={goalInfo.length && goalInfo[0].goal._id} teamMember={teamMember} />
      case 'TASK_ADDED':
        return (
          milestoneData && (
            <AddTasks
              getGoalsData={getGoalsData}
              onCloseDrawer={onCloseDrawer}
              user={Role.Customer}
              goalFormType={ProgramRole.MyTasks}
              chooseForm="View"
              propVisible={visibly}
              formTypeLabel="Tasks"
              notifyVisible
              addButtonLabel="Add Task"
              mileStoneData={milestoneData}
              taskData={taskData}
              taskId={taskId}
            />
          )
        );

      case 'TASK_MARKED':
        return (
          milestoneData && (
            <AddTasks
              getGoalsData={getGoalsData}
              onCloseDrawer={onCloseDrawer}
              user={Role.Customer}
              goalFormType={ProgramRole.MyTasks}
              chooseForm="View"
              propVisible={visibly}
              formTypeLabel="Tasks"
              notifyVisible
              addButtonLabel="Add Task"
              mileStoneData={milestoneData}
              taskData={taskData}
              taskId={taskId}
            />
          )
        );

      case 'SESSION_ADDED':
        return (
          <AddSessionNoteComponent
            onCloseDrawer={onCloseDrawer}
            user={Role.Customer}
            formTypeLabel="Activity"
            addButtonLabel="Add an Activity Note"
            chooseForm="View"
            propVisible={visibly}
            notifyVisible
            sessionId={sessionId}
          />
        );

      case 'FIRST_TIME_LOGIN':
        return <WizardModal visible={visibility} setVisibility={setVisibility} />;
      default:
        return '';
    }
  };
  const updateNotification = (item) => {
    if (item.type === 'FIRST_TIME_LOGIN') {
      setVisibility(true);
    }
    setLoading(true);
    putNotificationApi(item._id)
      .then((res) => {
        getNotificationApi(currentLoginUser._id, { limit: 100 }).then((res) => {
          const { data } = res;
          dispatch(getNotificationAlert(data));
          dispatch(putNotificationAlert(data));
          dispatch(notificationVisible(true));
          setNotificationType(item.type);
          if (item.goal) {
            setMileStoneNotification(item._id);
          }

          if (item.type === 'RESOURCE') {
            resourceDataRoute();
          }
          if (item.type === 'TASK_OVERDUE') {
            goalDataRoute();
          }
          if (item.session) {
            setSessionId(item.session._id);
          }
          if (item.task) {
            setTaskId(item.task._id);
            getTaskDetailsApi(item.task._id).then((res) => {
              const { data } = res;
              setTaskData(data);
              setMilestoneData(data.milestone);
            });
          }

          if (item.milestone) {
            setItemId(item.milestone._id);
            setMilestoneData(item.milestone);
          }
          setLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getNotificationApi(currentLoginUser._id, { limit: 100 })
      .then((res) => {
        const { data } = res;
        dispatch(getNotificationAlert(data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Card style={{ width: '100%' }}>
      <NotificationCard notify={filterNotificationTaskList} onClick={updateNotification} />
      {renderSwitch()}
    </Card>
  );
};

function mapStateToProps(state: any) {
  return {
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    customerInfo: state.customerRuducer.customerInfo,
    customerAccessData: state.customerRuducer.customerAccessData,
    visibly: state.notificationReducer.visible,
    notificationList: state.notificationReducer.notificationList,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(MyNotificationComponent);
