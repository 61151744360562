import React from 'react';
import ReportsTable from './ReportsTable';

export interface IReportsComponentProps {}

export default function ReportsComponent(props: IReportsComponentProps) {
  return (
    <div className="site-layout-background">
      <div className="p-4">
        <ReportsTable />
      </div>
    </div>
  );
}
