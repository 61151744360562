import { notification } from 'antd';
import moment from 'moment';
import { Role } from '../../utils/types/role';

export const dateFormat = 'DD-MMM-YYYY';

export const setDataInLocalStorage = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const removeDataInLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const getDataFromLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  try {
    return JSON.parse(data!);
  } catch (error) {
    console.log(error);
    return data;
  }
};

export const showNotificationDrawer = (title: string, description: string) => {
  const type = title.toLowerCase();
  if (type) {
    notification[type]({
      message: title,
      description,
      duration: 2.0
    });
  }
};

export const formattedDate = (date: any) => moment(date).format(dateFormat);

export const formattedDateWithDuration = (date: any) => moment(date).format('DD-MMM-YYYY hh:mm A');

export const formattedDuration = (time: any) => moment(time).format('HH:mm:ss a');

export const formattedTime = (time: any) => moment(time).format('hh:mm A');

export const getRouteLabel = (userType: string, route: string, customerAccessData: any) => {
  if (
    userType === Role.Customer ||
    (customerAccessData && customerAccessData.role === Role.BusinessAccesCustomer)
  ) {
    switch (route) {
      case '/dashboard':
        return 'Dashboard';
      case '/dashboard/myprogram':
        return 'My Programs';
      case '/dashboard/myforms':
        return 'Onboard Forms';
      case '/dashboard/myforms/details':
        return 'Onboard Form details';
      case '/dashboard/mysessions':
        return 'My Activities';
      case '/dashboard/myfavorites':
        return 'My Favorites';
      case '/dashboard/mylearning':
        return 'Library';
      case '/dashboard/myresources':
        return 'My Resources';
      case '/dashboard/myapps':
        return 'My Apps';
      case '/dashboard/mynotifications':
        return 'My Notifications';
      /**
       * ? temporarily block
       */
      // case '/dashboard/clientFiles':
      //   return 'My Files';
      // case '/dashboard/clientFiles/bin':
      //   return 'Bin';
      case '/dashboard/sharedVideos':
        return 'My Videos';
      case '/dashboard/reports':
        return 'Reports';
      default:
        return '';
    }
  } else if (userType === Role.Admin) {
    switch (route) {
      case '/dashboard':
        return 'Administrator';
      case '/dashboard/users/business':
        return 'Business';
      case '/dashboard/users/customer':
        return 'Customer';
      case '/dashboard/users/members':
        return 'Members';
      case '/dashboard/settings/tracking':
        return 'Tracking';
      case '/dashboard/resources':
        return 'Resources';
      case '/dashboard/workshops':
        return 'WorkShops';
      case '/dashboard/author':
        return 'Author';
      case '/dashboard/videos':
        return 'Videos';
      case '/dashboard/course':
        return 'Course';
      case '/dashboard/blogs':
        return 'Blogs';
      case '/dashboard/broadcastmessages':
        return 'Broadcast Messages';
      case '/dashboard/posts':
        return 'Posts';
      default:
        return '';
    }
  } else {
    switch (route) {
      case '/dashboard':
        return 'Customers';
      case '/dashboard/myprogram':
        return 'My Programs';
      case '/dashboard/myforms':
        return 'Onboard Forms';
      case '/dashboard/myforms/details':
        return 'Onboard Form details';
      case '/dashboard/mysessions':
        return 'My Activities';
      case '/dashboard/myfavorites':
        return 'My Favorites';
      case '/dashboard/mylearning':
        return 'Library';
      case '/dashboard/myapps':
        return 'My Apps';
      case '/dashboard/resources':
        return 'Resources';
      case '/dashboard/blogs':
        return 'Blogs';
      case '/dashboard/broadcastmessages':
        return 'Broadcast Messages';
      case '/dashboard/posts':
        return 'Posts';
      // case "/dashboard/workshops":
      //   return "WorkShops";
      // case "/dashboard/author":
      //   return "Author";
      // case "/dashboard/videos":
      //   return "Videos";
      case '/dashboard/users/members':
        return 'Members';
      case '/dashboard/mynotifications':
        return 'My Notifications';
      default:
        return '';
    }
  }
};

export const isSameDate = (dateA: string | Date, dateB: string | Date) => {
  const a = new Date(dateA);
  const b = new Date(dateB);
  if (a.getFullYear() != b.getFullYear()) return false;
  if (a.getMonth() != b.getMonth()) return false;
  if (a.getDate() != b.getDate()) return false;
  return true;
};
/**
 * transformation function used in react-html-parser
 * @param node
 * @param index
 * @returns string | null
 */
export const extractTextTransform = (node, index = 0) => {
  if (node.type === 'text') {
    return node.data;
  }
  if (node.children.length > 0)
    return node.children.map((one) => extractTextTransform(one)).join(' ');

  return ' '; // Return empty string for non-text nodes to ignore them
};
