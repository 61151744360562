import { Button, Col, DatePicker, Form, Input, Select, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { exportSessionReportApi } from '../../api/sessionReport';
import { listUserApi } from '../../api/user';
import CrossFormButton from '../../images/icons/crossFormButton.svg';
import Modal from '../../styled-components/Modal/Modal';
import { Role } from '../../utils/types/role';
import { getDataFromLocalStorage, setDataInLocalStorage } from '../common/utils';
import { ReportTypes } from './types';

interface Pagination {
  page: number;
  pageSize?: number;
  total: number;
}

interface SessionReportModalProps {
  open;
  onClose;
}

const SessionReportModal: React.FC<SessionReportModalProps> = ({ open, onClose }) => {
  const [form] = Form.useForm();
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    const data = getDataFromLocalStorage(ReportTypes.Session) as SessionReport;
    if (data) {
      form.setFieldsValue({
        ...data,
        from: moment(data.from),
        to: moment(data.to),
        customerEmails: data.customerEmails?.join(', ')
      });
    }
  }, []);

  const { data: businessUsers } = useQuery({
    queryKey: ['businessUsers'],
    queryFn: () => {
      return listUserApi(Role.Business, {});
    }
  });

  const options = businessUsers?.data.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user._id
  }));

  const handleSubmit = async (values) => {
    // console.log(values);
    setIsExporting(true);
    values['customerEmails'] = values['customerEmails']
      .split(',')
      .map((email) => email.trim())
      .filter(Boolean);
    try {
      const exportData = await exportSessionReportApi(values);
      // download response
      const url = window.URL.createObjectURL(new Blob([exportData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);
      link.click();

      setDataInLocalStorage(ReportTypes.Session, values);
    } catch (e) {
      console.error(e);
    }
    setIsExporting(false);
    onClose();
  };

  return (
    <Modal
      className="user-drawer"
      maskClosable={false}
      title="Session Report"
      centered
      width={640}
      visible={open}
      onCancel={onClose}
      closeIcon={<img src={CrossFormButton} data-testid="close-btn" />}
      footer={null}
    >
      <Form
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        name="sessionReportForm"
        form={form}
        onFinish={handleSubmit}
      >
        <Spin spinning={isExporting}>
          <Col span={24}>
            <Form.Item label="Coach Name" name="businessUserIds">
              <Select
                filterOption={true}
                mode="multiple"
                options={options}
                optionFilterProp="label"
                data-testid="business-user-ids-input"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Customer Email"
              name="customerEmails"
              rules={[
                {
                  message: 'Please enter valid email addresses!',
                  validator: (_, value: string) => {
                    if (!value) {
                      return Promise.resolve();
                    }
                    const emails = value.split(',').map((email) => email.trim());
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (emails.every((email) => emailRegex.test(email))) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error('Invalid email format!'));
                    }
                  }
                }
              ]}
            >
              <Input data-testid="customer-emails-input" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Session Date From"
              name="from"
              rules={[{ required: true, message: 'Please select from date!' }]}
            >
              {/* @ts-ignore */}
              <DatePicker
                className="w-full"
                disabledDate={(date) => date > moment()}
                data-testid="session-date-from-input"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Session Date To"
              name="to"
              rules={[{ required: true, message: 'Please select to date!' }]}
            >
              {/* @ts-ignore */}
              <DatePicker
                className="w-full"
                disabledDate={(date) => date > moment()}
                data-testid="session-date-to-input"
              />
            </Form.Item>
          </Col>
        </Spin>
        <div className="drawer-footer-style drawerButton">
          <Button type="primary" htmlType="submit" disabled={isExporting} data-testid="export-btn">
            Export CSV Report
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SessionReportModal;
