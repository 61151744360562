import { API_URL } from '../constant';
import { apiGet, apiPatch, apiPost } from '../utils/api';
import { Role } from '../utils/types/role';

const headers = {
  Authorization: 'Bearer keysngnjGgAAjDzwM'
};
export const listUserApi = (type, options) => apiGet(`${API_URL}user/all/${type}`, options);

export const createUserApi = (data) => apiPost(`${API_URL}user`, data);

export const getUserApi = (profileId) => apiGet(`${API_URL}user/${profileId}`);

export const getSignedUser = () => apiGet(`${API_URL}user/me`);

export const getEmailSignature = () => apiGet(`${API_URL}user/me/emailSignature`);

export const getPublicUserApi = (id, type) => apiGet(`${API_URL}user/public/${type}/${id}`);

export const updateUsersApi = (profileId, data) => apiPatch(`${API_URL}user/${profileId}`, data);

export const searchUserApi = (type, options) => apiGet(`${API_URL}user/all/${type}`, options);

export const leanUserApi = (type, options) => apiGet(`${API_URL}user/lean/${type}`, options);

export const paginationApi = (type, filter, skip, limit) =>
  apiGet(`${API_URL}user/all/${type}`, { filter, skip, limit });

export const patchFirstLogin = (id) => apiPatch(`${API_URL}user/firstLogin/${id}`);

export const controlCalendar = (id, value) =>
  apiGet(`${API_URL}controlCalWatch/?id=${id}&value=${value}`);

export const listCSMApi = async () => {
  const { data } = await apiGet(`${API_URL}user/all/${Role.Business}`, {
    filter: 'Active'
  });
  return data.filter((user) => user.businessUserType?.includes('Customer Manager'));
};

export const getSocialAssessmentQuestion = (ques) =>
  apiGet(`${API_URL}socialAssessmentQuestion/${ques}`);

export const getCalSyncState = (id) => apiGet(`${API_URL}calSyncState/?id=${id}`);

export const getTags = () => apiGet(`${API_URL}tags`);

export const getMeApi = () => apiGet(`${API_URL}user/me`);

export const updateMeApi = (data) => apiPatch(`${API_URL}user/me`, data);
