import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Pagination, Row, Select, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  getFavouriteResourcesDetailAPI,
  getResourceObjective,
  getResourcesAPI,
  getResourceType
} from '../api/resources';
import { listUserApi } from '../api/user';
import { UserContainer } from '../pages/dashboard/styles';
import { setCustomerLoading } from '../redux/actions/customerAction';
import {
  setFetchingResource,
  setIsOpenDrawer,
  setObjectiveList,
  setResourceTypes,
  setShowFormData
} from '../redux/actions/formAction';
import { Mode, Role } from '../utils/types/role';
import AddResources from './AddUser/AddResources';
import { AddButton, CustomerContainer, FilterDiv } from './common/styles';
import ResourceListComponent from './TableData/ResourceListComponent';
import ResourcesTableComponent from './TableData/ResourcesTableComponent';

const { Option } = Select;

const ResourcesComponent: React.FC<any> = (props) => {
  const {
    dispatch,
    customerLoading,
    objectiveList,
    resourceTypes,
    currentLoginUser,
    resourceListMode,
    onlyDisplayActiveResources,
    isMobile,
    addskillresource
  } = props;
  const { id } = useParams();

  const [checkboxChecked, setcheckboxChecked] = useState({});
  const [activeForm, setActiveForm] = useState<any>({
    search: '',
    type: '',
    objective: '',
    owner: '',
    isFavourites: false
  });
  const [ownerList, setOwnerList] = useState<any>([]);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 50,
    total: null
  });

  const fetchResouceList = useCallback(
    (value, option) => {
      const data = {
        search: value.search ? value.search : '',
        type: value.type && value.type.length ? value.type : '',
        objective: value.objective && value.objective.length ? value.objective : '',
        owner: value.owner && value.owner.length ? value.owner : '',
        isFavourites: value.isFavourites
      };

      const options = {
        skip: option.current * option.pageSize - option.pageSize,
        limit: option.pageSize
      };

      dispatch(setCustomerLoading(true));

      if (value.isFavourites) {
        return getFavouriteResourcesDetailAPI(data, options);
      } else {
        return getResourcesAPI(data, options);
      }
    },
    [dispatch]
  );

  const fetchUserList: any = useCallback((role) => {
    const options = {
      filter: 'Active',
      search: '',
      skip: 0,
      limit: ''
    };

    return listUserApi(role, options);
  }, []);

  useEffect(() => {
    fetchResouceList(activeForm, pagination).then((res) => {
      const { data, count } = res;
      setPagination({
        ...pagination,
        total: count
      });
      dispatch(setFetchingResource(data));
      dispatch(setCustomerLoading(false));
    });
    getResourceObjective().then((res) => {
      if (res && res.data) {
        dispatch(setObjectiveList(res.data));
      }
    });
    getResourceType().then((res) => {
      if (res && res.data) {
        dispatch(setResourceTypes(res.data));
      }
    });

    Promise.all([fetchUserList(Role.Business), fetchUserList(Role.Admin)]).then((values) => {
      const userData = values.map((item) => item.data);
      setOwnerList(userData[0].concat(userData[1]));
    });
  }, [dispatch, fetchUserList]); // eslint-disable-line react-hooks/exhaustive-deps

  const openDrawar = (e: any) => {
    e.stopPropagation();

    const createFormData = {
      id: '',
      userType: currentLoginUser.type,
      formMode: Mode.Create,
      formLabel: 'Resources'
    };
    dispatch(setShowFormData(createFormData));
    dispatch(setIsOpenDrawer(true));
  };

  const handleChangeList = (name: string, value: any) => {
    let dataVal;
    if (name === 'objective') {
      dataVal = objectiveList.filter((obj) => {
        if (value.includes(obj.name)) {
          return obj._id;
        }
      });
    }
    if (name === 'type') {
      dataVal = resourceTypes.filter((obj) => {
        if (value.includes(obj.name)) {
          return obj._id;
        }
      });
    }
    if (name === 'owner') {
      dataVal = ownerList.filter((obj) => {
        if (value.includes(obj.firstName)) {
          return obj._id;
        }
      });
    }
    if (name && dataVal) {
      dataVal = dataVal.map((val) => val._id);
      const data = {
        ...activeForm,
        [name]: dataVal
      };
      setActiveForm(data);
      fetchResouceList(data, pagination).then((res) => {
        const { data, count } = res;
        setPagination({
          ...pagination,
          total: count
        });
        dispatch(setFetchingResource(data));
        dispatch(setCustomerLoading(false));
      });
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const data = {
      ...activeForm,
      search: value
    };
    setActiveForm(data);
    if (!value) {
      fetchResouceList({ ...activeForm, search: '' }, pagination)
        .then((res) => {
          const { data, count } = res;
          setPagination({
            ...pagination,
            total: count
          });
          dispatch(setFetchingResource(data));
          dispatch(setCustomerLoading(false));
        })
        .catch((error) => {
          dispatch(setCustomerLoading(false));
        });
    }
  };

  const onPageChange = useCallback(
    (page: number, pagesize) => {
      const option = {
        ...pagination,
        current: page,
        pageSize: pagesize
      };

      setPagination(option);
      fetchResouceList(activeForm, option)
        .then((res) => {
          const { data, count } = res;
          dispatch(setFetchingResource(data));
          dispatch(setCustomerLoading(false));
        })
        .catch((error) => {
          dispatch(setCustomerLoading(false));
        });
    },
    [dispatch, fetchResouceList, activeForm, pagination]
  );

  const onSearch = useCallback(
    (value) => {
      if (value.search.trim() === '') {
        return;
      }
      fetchResouceList(value, pagination)
        .then((res) => {
          const { data, count } = res;
          setPagination({
            ...pagination,
            total: count
          });

          dispatch(setFetchingResource(data));
          dispatch(setCustomerLoading(false));
        })
        .catch((error) => {
          dispatch(setCustomerLoading(false));
        });
    },
    [dispatch, fetchResouceList, pagination]
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onSearch(activeForm);
      }
    },
    [onSearch, activeForm]
  );

  const onChangeFavResource = useCallback((e) => {
    setcheckboxChecked(e);
    const checked = e.target ? e.target.checked : false;
    const data = {
      ...activeForm,
      isFavourites: checked
    };
    setActiveForm(data);
    fetchResouceList(data, pagination)
      .then((res) => {
        const { data, count } = res;
        setPagination({
          ...pagination,
          current: 1,
          total: count
        });
        dispatch(setFetchingResource(data));
        dispatch(setCustomerLoading(false));
      })
      .catch((error) => {
        dispatch(setCustomerLoading(false));
      });
  }, []);

  const sortObjectiveList = objectiveList.sort((a, b) => a.name.localeCompare(b.name));
  const sortResourceTypes = resourceTypes.sort((a, b) => a.name.localeCompare(b.name));
  const sortOwnerList = ownerList.sort((a, b) => a.firstName.localeCompare(b.firstName));

  return (
    <div>
      <Spin spinning={customerLoading}>
        {resourceListMode !== Mode.Create &&
        (currentLoginUser.type === Role.Business || currentLoginUser.type === Role.Admin) ? (
          <UserContainer className="resources-container-mobileview">
            {/* <ProgramAddButton className="add-goal-button" icon={<img src={Plus} className={isMobile ? "Plus-Icon-mobile-view" : ""}/>} onClick={openDrawar}>
                            {"Resources"}
                        </ProgramAddButton> */}
            <AddButton type="primary" onClick={openDrawar}>
              <PlusOutlined />
            </AddButton>
            {currentLoginUser.type === Role.Admin && (
              <Link
                to="/dashboard/resources/categories"
                className="rounded border-w-2 border border-solid p-1 ml-3"
              >
                View Categories
              </Link>
            )}
            <AddResources pagination={pagination} activeForm={activeForm} />
          </UserContainer>
        ) : null}

        <div className="site-layout-background">
          <Row style={{ marginTop: 10 }}>
            <Col xs={24} md={5}>
              <FilterDiv className="search-box">
                <Button onClick={() => onSearch(activeForm)} className="SearchButton">
                  {' '}
                  <SearchOutlined />
                </Button>
                <Input
                  onKeyPress={handleKeyPress}
                  onKeyUp={() => onSearch(activeForm)}
                  name="search"
                  value={activeForm.search.trim()}
                  onChange={handleChange}
                  placeholder="Search resources..."
                />
              </FilterDiv>
            </Col>

            <Col xs={24} md={5}>
              <FilterDiv className="select-box">
                {sortObjectiveList && sortObjectiveList.length ? (
                  <Form.Item label="" name="objective">
                    <Select
                      mode="multiple"
                      onChange={(value) => handleChangeList('objective', value)}
                      placeholder="Filter by objective"
                    >
                      {sortObjectiveList.map((data, index: number) => (
                        <Option key={index} value={data.name}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : null}
              </FilterDiv>
            </Col>
            <Col xs={24} md={5}>
              <FilterDiv className="select-box">
                {sortResourceTypes && sortResourceTypes.length ? (
                  <Form.Item label="" name="type">
                    <Select
                      mode="multiple"
                      onChange={(value) => handleChangeList('type', value)}
                      placeholder="Filter by types"
                    >
                      {resourceTypes.map((data, index: number) => (
                        <Option key={index} value={data.name}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <Select />
                )}
              </FilterDiv>
            </Col>

            <Col xs={24} md={5}>
              <FilterDiv className="select-box">
                {sortOwnerList && sortOwnerList.length ? (
                  <Form.Item label="" name="owner">
                    <Select
                      mode="multiple"
                      onChange={(value) => handleChangeList('owner', value)}
                      placeholder="Filter by owner"
                    >
                      {ownerList.map((data, index: number) => (
                        <Option
                          key={index}
                          value={data.firstName}
                        >{`${data.firstName} ${data.lastName ? data.lastName : ''}`}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <Select />
                )}
              </FilterDiv>
            </Col>

            <Col xs={24} md={4}>
              <FilterDiv className="check-box">
                <Checkbox onChange={onChangeFavResource}>Favourite</Checkbox>
              </FilterDiv>
            </Col>
          </Row>
          <div className="site-card-wrapper">
            <CustomerContainer>
              {addskillresource ? (
                <ResourcesTableComponent
                  className="resources-table "
                  resourcesId={id}
                  onlyDisplayActiveResources={onlyDisplayActiveResources}
                  onChangeFavResource={onChangeFavResource}
                  checkboxChecked={checkboxChecked}
                />
              ) : (
                <ResourceListComponent
                  className="resources-table"
                  resourcesId={id}
                  onlyDisplayActiveResources={onlyDisplayActiveResources}
                  onChangeFavResource={onChangeFavResource}
                  checkboxChecked={checkboxChecked}
                />
              )}
              <Pagination
                // hideOnSinglePage={true}
                current={pagination.current}
                total={pagination.total}
                pageSize={pagination.pageSize}
                onChange={onPageChange}
                className="my-pagination"
              />
            </CustomerContainer>
          </div>
        </div>
      </Spin>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    programSkills: state.programRuducer.programSkills,
    programComlexityList: state.programRuducer.programComlexityList,
    customerLoading: state.customerRuducer.customerLoading,
    sessionData: state.sessionReducer.sessionData,
    objectiveList: state.formReducer.objectiveList,
    resourceTypes: state.formReducer.resourceTypes,
    resourceListMode: state.formReducer.resourceListMode,
    isMobile: state.trackReducer.isMobile,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(ResourcesComponent);
