import { Badge, Popover, Spin, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getNotificationApi, putNotificationApi } from '../../../api/notification';
import { getUserApi, updateUsersApi } from '../../../api/user';
import { getGoalsApi, getTaskDetailsApi } from '../../../api/user-program';
import NotificationIcon from '../../../images/icons/notificationIcon.svg';
import { setCustomerLoading } from '../../../redux/actions/customerAction';
import {
  getCustomerApi,
  getNotificationAlert,
  notificationVisible
} from '../../../redux/actions/notificationAction';
import { setBannerHeaderLabel } from '../../../redux/actions/trackingAction';
import { setMemberGoals } from '../../../redux/actions/userProgramAction';
import { logOut } from '../../../utils/api';
import { Role } from '../../../utils/types/role';
import AddGoalComponent from '../../AddUser/AddGoalComponent';
import AddSessionNoteComponent from '../../AddUser/AddSessionNoteComponent';
import AddTasks from '../../AddUser/AddTasks';
import { ProgramRole } from '../../MyGoalsComponent';
import MilestoneForm from '../../refactored/Drawer/Milestone/MilestoneForm';
import SideDrawer from '../../refactored/Drawer/SideDrawer';
import WizardModal from '../../refactored/Modal/Wizard/Wizard';
import { AddButton, CardBottomLink } from '../styles';
import NotificationCard from './NotificationCard';

const { Title } = Typography;

const NotificationPopup: React.FC<any> = (props) => {
  const { dispatch, notify, visibly, notificationList, customerDataList, currentLoginUser } = props;
  const history = useHistory();

  const [showNotification, setNotification] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [visibility, setVisibility] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<any>();
  const [notificationType, setNotificationType] = useState<any>();
  const [chooseKey, setKey] = useState<string>('Active');
  const [mileStoneNotification, setMileStoneNotification] = useState<any>();
  const [itemId, setItemId] = useState<any>();
  const [milestoneData, setMilestoneData] = useState<any>();
  const [taskData, setTaskData] = useState<any>();
  const [sessionId, setSessionId] = useState<any>();
  const [taskId, setTaskId] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);
  //   console.log(notificationList,"notification List");
  // const notificationListFilter =  notificationList && notificationList?.sort((a, b) => {
  //   const x = new Date(b.createdAt) as any;
  //   const y = new Date(a.createdAt) as any;
  //   return (x - y)
  // });
  // console.log("filter", notificationListFilter);

  const goalInfo =
    notificationList &&
    notificationList.length &&
    notificationList.filter((data) => data._id === mileStoneNotification);
  useEffect(() => {
    getNotificationApi(currentLoginUser._id).then((res) => {
      const { data } = res;
      dispatch(getNotificationAlert(data));
    });
  }, []);

  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
    dispatch(notificationVisible(false));
  }, [chooseKey]);

  const getGoalsData = () => {
    if (goalInfo && goalInfo.member) {
      getGoalsApi(goalInfo.member).then((res) => {
        dispatch(setMemberGoals(res.data));
        dispatch(setCustomerLoading(false));
      });
    }
  };
  const resourceDataRoute = () => {
    const path = '/dashboard/myresources';
    history.push(path);
    dispatch(setBannerHeaderLabel(path));
    return null;
  };
  const goalDataRoute = () => {
    const path = '/dashboard/myprogram';
    history.push(path);
    dispatch(setBannerHeaderLabel(path));
    return null;
  };
  const firstTimeRoute = () => {
    const path = '/getting-started';
    history.push(path);
    dispatch(setBannerHeaderLabel(path));
    return null;
  };
  const renderSwitch = () => {
    switch (notificationType) {
      case 'GOAL_ADDED':
        return (
          <AddGoalComponent
            onCloseDrawer={onCloseDrawer}
            user={Role.Customer}
            addButtonLabel="Add Outcome"
            formTypeLabel="Outcome"
            chooseForm="View"
            propVisible={visibly}
            notifyVisible
            id={goalInfo.length && goalInfo[0] && goalInfo[0].goal._id}
          />
        );

      case 'GOAL_UPDATED':
        return (
          <AddGoalComponent
            onCloseDrawer={onCloseDrawer}
            user={Role.Customer}
            addButtonLabel="Add Outcome"
            formTypeLabel="Outcome"
            chooseForm="View"
            propVisible={visibly}
            notifyVisible
            id={goalInfo.length && goalInfo[0] && goalInfo[0].goal._id}
          />
        );

      case 'MILESTONE_ADDED':
        return (
          <SideDrawer visible={visibly} title="Skill">
            <MilestoneForm milestoneId={itemId} onCloseDrawer={onCloseDrawer} visible={visibly} />
          </SideDrawer>
        );
      case 'TASK_ADDED':
        return (
          milestoneData && (
            <AddTasks
              getGoalsData={getGoalsData}
              onCloseDrawer={onCloseDrawer}
              user={Role.Customer}
              goalFormType={ProgramRole.MyTasks}
              chooseForm="View"
              propVisible={visibly}
              formTypeLabel="Tasks"
              notifyVisible
              addButtonLabel="Add Task"
              mileStoneData={milestoneData}
              taskData={taskData}
              taskId={taskId}
            />
          )
        );
      case 'TASK_OVERDUE':
        return (
          milestoneData && (
            <AddTasks
              getGoalsData={getGoalsData}
              onCloseDrawer={onCloseDrawer}
              user={Role.Customer}
              goalFormType={ProgramRole.MyTasks}
              chooseForm="View"
              propVisible={visibly}
              formTypeLabel="Tasks"
              notifyVisible
              addButtonLabel="Add Task"
              mileStoneData={milestoneData}
              taskData={taskData}
              taskId={taskId}
            />
          )
        );
      case 'TASK_MARKED':
        return (
          milestoneData && (
            <AddTasks
              getGoalsData={getGoalsData}
              onCloseDrawer={onCloseDrawer}
              user={Role.Customer}
              goalFormType={ProgramRole.MyTasks}
              chooseForm="View"
              propVisible={visibly}
              formTypeLabel="Tasks"
              notifyVisible
              addButtonLabel="Add Task"
              mileStoneData={milestoneData}
              taskData={taskData}
              taskId={taskId}
            />
          )
        );

      case 'SESSION_ADDED':
        return (
          <AddSessionNoteComponent
            onCloseDrawer={onCloseDrawer}
            user={Role.Customer}
            formTypeLabel="Activity"
            addButtonLabel="Add an Activity Note"
            chooseForm="View"
            propVisible={visibly}
            viewIconDisable
            notifyVisible
            sessionId={sessionId}
          />
        );

      case 'RESOURCE':
        return resourceDataRoute();

      case 'FIRST_TIME_LOGIN':
        return <WizardModal visible={visibility} setVisibility={setVisibility} />;

      default:
        return '';
    }
  };

  const handleClick = (value, vis) => {
    setVisible(vis);
    setIsActive(value);
    updateUsersApi(customerDataList._id, {
      unreadCount: 0
    }).then((res) => {
      getUserApi(customerDataList._id).then((res) => {
        const data = res;
        dispatch(getCustomerApi(data));
      });
    });
  };
  // const handleBlur=(value,vis)=>{
  //     setVisible(false)
  // }

  const onLoadMoreData = () => {
    const limit = 10;
    const skip = notificationList.length;
    setLoading(true);
    getNotificationApi(currentLoginUser._id, { skip, limit }).then((res) => {
      const { data } = res;
      dispatch(getNotificationAlert([...notificationList, ...data]));
      setLoading(false);
    });
  };

  const onLogoutClick = () => {
    logOut();
    history.push('/login');
  };

  const updateNotification = (item) => {
    if (item.type === 'FIRST_TIME_LOGIN') {
      setNotificationType(item.type);
      setVisibility(true);
      return;
    }
    setLoading(true);
    putNotificationApi(item._id).then((res) => {
      dispatch(notificationVisible(true));
      setNotificationType(item.type);
      if (item.type === 'RESOURCE') {
        resourceDataRoute();
      }
      if (item.type === 'TASK_OVERDUE' && item.task) {
        setTaskId(item.task._id);
        getTaskDetailsApi(item.task._id).then((res) => {
          const { data } = res;
          setTaskData(data);
          setMilestoneData(data.milestone);
        });
      }
      if (item.goal) {
        setMileStoneNotification(item._id);
      }
      if (item.type === 'FIRST_TIME_LOGIN') {
        firstTimeRoute();
      }
      if (item.session) {
        setSessionId(item.session._id);
      }
      if (item.task) {
        setTaskId(item.task._id);
        getTaskDetailsApi(item.task._id).then((res) => {
          const { data } = res;
          setTaskData(data);
          setMilestoneData(data.milestone);
        });
      }

      if (item.milestone) {
        setItemId(item.milestone._id);
        setMilestoneData(item.milestone);
      }
      setLoading(false);
      setVisible(false);
    });
  };
  const notificationTitle = <span className="title-styling title-text-style">Notifications</span>;

  const content = (
    <Spin className="sliderNotfication" spinning={isLoading}>
      <div className="notification-pop-menu">
        <NotificationCard notify={notify} visibly={visibly} onClick={updateNotification} />
        <CardBottomLink className="card-bootom-link notification-load-button">
          <span className="right-link" onClick={onLoadMoreData}>
            Load More
          </span>
        </CardBottomLink>
      </div>
    </Spin>
  );

  return (
    <Popover
      content={content}
      title={notificationTitle}
      trigger="click"
      placement="topRight"
      overlayStyle={{ zIndex: 99 }}
      overlayClassName="notification-popover"
      // visible={visible}
    >
      <Badge
        size="small"
        count={customerDataList.unreadCount > 0 ? customerDataList.unreadCount : ''}
        className="bell-notification"
      >
        <AddButton
          className={isActive === 2 ? 'active' : ''}
          onClick={() => handleClick(2, !visible)}
          // onBlur={()=>handleBlur(2,visible)}
        >
          <img src={NotificationIcon} />
        </AddButton>
        {/* {showNotification ? (
          <UserProfileContainer style={{zIndex:99}}>
            <ProfileFooter>
              <Button onClick={onLogoutClick}>Load more</Button>
            </ProfileFooter>
          </UserProfileContainer>
        ) : null} */}
        {notificationType !== 'RESOURCE' && renderSwitch()}
      </Badge>
    </Popover>
  );
};

function mapStateToProps(state: any) {
  return {
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    customerInfo: state.customerRuducer.customerInfo,
    customerAccessData: state.customerRuducer.customerAccessData,
    visibly: state.notificationReducer.visible,
    notificationList: state.notificationReducer.notificationList,
    customerDataList: state.notificationReducer.customerDataList,
    memberGoals: state.programRuducer.memberGoals,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(NotificationPopup);
